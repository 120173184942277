$primary: #0266b3;
$danger: #dc3545;

@import './bootstrap-utilities/_index';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';

@import 'elements';
@import 'layout';
@import 'forms';
@import 'tables';
@import 'search';
@import 'articles';
@import 'attachments';
@import 'extensions';
@import 'products';
@import 'manufacturers';
@import 'cart';
@import 'fancybox';
@import 'components/sidebar';
@import 'shortcodes';

@import 'components/account';
@import 'components/breadcrumbs';

@import 'pages/home';

@import 'utilities';

@import 'template'