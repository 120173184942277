#product-list, 
.product-box {
	.imagebox.style1.v3 {
		display: flex;
	}

	.box-image {
		a {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
		}

		img {
			align-self: center;
			border: 1px solid #e0e0e0;
		}
	}

	.box-content {
		width: auto;
		flex: 1 0 0;
	}

	.box-price {
		max-width: 200px;
		text-align: right;
		margin-left: auto;
		padding-left: 40px;
	}

	.product-name a {
		font-size: 16px;
	}

	.cat-name {
		font-size: 13px;
	}

	.box-price {
		display: flex;
		flex-direction: column;

		.regular {
			font-size: 16px;
            color: #f00;

            > span {
                color: #777;
            }
		}
	}

	.btn-add-cart {
		display: block;
		max-width: 100%;
	}

	.box-cart {
		margin: 0;
	}

	.add-to-cart-container {
		display: flex;
		align-items: center;
	}

	.add-to-cart-link {
		padding: 0;
		margin: 0 ;
		width: auto;
		background-image: url(/StaticFiles/template/images/icons/add-cart.png);
		background-position: center center;
		background-repeat: no-repeat;
		text-indent: -9999px;
		max-width: 100%;
	}
	

	.quanlity-box,
	.box-cart {
		width: 50%;
		padding: 0 5px;
	}

	.quanlity input {
		padding: 10px;
	}
}

#product-list {
	.box-image {
		background-color: #fff;
	}
}

#product-detail {
	.zoom[href="#"] {
		pointer-events: none;
	}

	.add-to-cart-container {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.add-to-cart-link {
		background-image: url(/StaticFiles/template/images/icons/add-cart.png);
		background-position: 25px center;
		background-repeat: no-repeat;
		padding-left: 70px;
		padding-right: 40px;
		width: auto;
	}

	.product-detail {
		padding: 10px 15px;
	}

	.status-product span {
		padding: 0;
		height: auto;
		margin: 0;
		line-height: 1;
		background-color: transparent;

		&.text-in-stock { @extend .text-in-stock; }
		&.text-out-of-stock { @extend .text-out-of-stock; }
	}

	.header-detail {
		.heading {
			border-bottom: 1px solid #e2e2e2;
			margin-bottom: 5px;
			padding-bottom: 8px;
			margin-right: 10px;
			display: flex;
			align-items: center;
			width: 100%;

			span {
				margin-right: 13px;
				font-style: italic;
				font-size: 16px;
				font-weight: 600;
				white-space: nowrap;
			}
		}

		h4.name {
			font-size: 23px;
			margin: 0;
		}

		.manufacturer {
			margin-left: auto;
			width: 110px;
			flex-shrink: 0;
			
			img {
				object-fit: contain; 				
			}
		}

		.category a {
			color: $primary;
			display: block;
			font-size: 14px;
		}
	}	
	.box-cart {
		&.style2 {
			margin-bottom: 0;
		}
	}

	.call-for-price > span {
		display: inline-block;
		padding: 10px 25px;
		margin-top: 30px;
		background-color: #cee6ce;
		border-radius: 30px;
	}

}

.flat-product-detail {
    padding-bottom: 0;
}
.imagebox.style4 .box-content .product-name {
	line-height: 1.2;
	
	a {
		line-height: 1;
	}
}

.flat-product-content {
	background-color: transparent;

	.ajax__tab_header {
		text-align: center;
	}

	.ajax__tab_tab {
		display: inline-block;
		color: #919191;
		font-size: 20px;
		font-weight: 500;
		font-family: 'Open Sans';
		cursor: pointer;
		padding: 14px 30px 14px 26px;
		position: relative;
		z-index: 5;

		&:before {
			content: '';
			position: absolute;
			background-color: #f5f5f5;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			top: 0px;
			left: 0;
			width: 100%;
			height: 52px;
			z-index: -1;
			opacity: 0;
		}
	}
	.ajax__tab_active {
		.ajax__tab_tab {
			color: #2d2d2d;

			&:before {
				opacity: 1;
			}
		}
	}

	.ajax__tab_body {
		background: #f5f5f5;
		padding: 45px 0 25px;
	}
}

.tecnical-specs {
	text-align: center;
	
	table tr {
		td {
			padding-right: 45px;
			text-align: left;
			padding-left: 45px;
			width: 50%;
		}
		
		td:first-child {
			text-align: right;
		}
	
	}
	
}

.text-in-stock {
	color: #31984b;
}
.text-out-of-stock {
	color: #da2626;
}

