#table-cart {
	th,
	td {
		padding-left: 10px;
		padding-right: 10px;
	}
	th {
		text-align: center;
	}
	td {
		width: auto;

		&:not(:first-child) {
			text-align: center;
		}
	}

	td:first-child {
		max-width: 250px;
	}

	.price {
		text-align: center;
	}

	.quanlity {
		display: inline-block;
	}
}

.cart-totals .product-table-container {
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #e5e5e5;
}

.cart-totals table.product {
	th,
	td {
		padding-left: 5px!important;
		padding-right: 5px!important;
	}
	td a {
		color: #838383;

		&:hover {
			color: $primary;
		}
	}
}