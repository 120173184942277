@mixin fa {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }

.files {
	a,
	a[href*=".pdf"],
	a[href*=".doc"],
	a[href*=".docx"],
	a[href*=".xls"],
	a[href*=".xlsx"],
	a[href*=".jpg"],
	a[href*=".jpeg"],
	a[href*=".png"],
	a[href*=".avi"],
	a[href*=".mp4"],
	a[href*=".txt"],
	a[href*=".ppt"],
	a[href*=".pptx"] {
		&:before {
			@include fa;
			font-size: 21px;
			margin-right: 10px;
			content: '\f016';
		}
	}

	a[href*=".pdf"] {
		&:before { color: #cb0606; content: '\f1c1'; }
	}

	a[href*=".doc"],
	a[href*=".docx"] {
		&:before { color: #2b579a; content: "\f1c2"; }
	}

	a[href*=".xls"],
	a[href*=".xlsx"] {
		&:before { color: #1f7246; content: "\f1c3"; }
	}

	a[href*=".jpg"],
	a[href*=".jpeg"],
	a[href*=".png"] {
		&:before { color: #175b98; content: "\f1c5"; }
	}

	a[href*=".avi"],
	a[href*=".mp4"] {
		&:before { content: "\f1c8"; }
	}

	a[href*=".txt"] {
		&:before {color: #157efb; content: "\f0f6"; }
	}

	a[href*=".ppt"],
	a[href*=".pptx"] {
		&:before { color: #d14424; content: "\f1c4"; }
	}

	a[href*=".zip"],
	a[href*=".rar"] {
		&:before { color: #ff9100; content: "\f1c6"; }
	}		
}

.files-list {
	padding: 20px;
	background-color: #f7f7f7;

	&:first-child { margin-top: 0; }
	&:last-child { margin-bottom: 0; }

	.list-heading {
		margin-top: 0;
		margin-bottom: 18px;
	}

	ul {
		list-style:none;
	}

	li {
		margin-top: 8px;

		&:first-child { margin-top: 0; }
	}

	a {
		&:hover { text-decoration: underline; }
	}
}
