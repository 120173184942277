@import url(~@fancyapps/fancybox/dist/jquery.fancybox.min.css);
.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 1.25px !important; }

.mt-1,
.my-1 {
  margin-top: 1.25px !important; }

.mr-1,
.mx-1 {
  margin-right: 1.25px !important; }

.mb-1,
.my-1 {
  margin-bottom: 1.25px !important; }

.ml-1,
.mx-1 {
  margin-left: 1.25px !important; }

.m-2 {
  margin: 2.5px !important; }

.mt-2,
.my-2 {
  margin-top: 2.5px !important; }

.mr-2,
.mx-2 {
  margin-right: 2.5px !important; }

.mb-2,
.my-2 {
  margin-bottom: 2.5px !important; }

.ml-2,
.mx-2 {
  margin-left: 2.5px !important; }

.m-3 {
  margin: 5px !important; }

.mt-3,
.my-3 {
  margin-top: 5px !important; }

.mr-3,
.mx-3 {
  margin-right: 5px !important; }

.mb-3,
.my-3 {
  margin-bottom: 5px !important; }

.ml-3,
.mx-3 {
  margin-left: 5px !important; }

.m-4 {
  margin: 7.5px !important; }

.mt-4,
.my-4 {
  margin-top: 7.5px !important; }

.mr-4,
.mx-4 {
  margin-right: 7.5px !important; }

.mb-4,
.my-4 {
  margin-bottom: 7.5px !important; }

.ml-4,
.mx-4 {
  margin-left: 7.5px !important; }

.m-5 {
  margin: 5px !important; }

.mt-5,
.my-5 {
  margin-top: 5px !important; }

.mr-5,
.mx-5 {
  margin-right: 5px !important; }

.mb-5,
.my-5 {
  margin-bottom: 5px !important; }

.ml-5,
.mx-5 {
  margin-left: 5px !important; }

.m-10 {
  margin: 10px !important; }

.mt-10,
.my-10 {
  margin-top: 10px !important; }

.mr-10,
.mx-10 {
  margin-right: 10px !important; }

.mb-10,
.my-10 {
  margin-bottom: 10px !important; }

.ml-10,
.mx-10 {
  margin-left: 10px !important; }

.m-15 {
  margin: 15px !important; }

.mt-15,
.my-15 {
  margin-top: 15px !important; }

.mr-15,
.mx-15 {
  margin-right: 15px !important; }

.mb-15,
.my-15 {
  margin-bottom: 15px !important; }

.ml-15,
.mx-15 {
  margin-left: 15px !important; }

.m-20 {
  margin: 20px !important; }

.mt-20,
.my-20 {
  margin-top: 20px !important; }

.mr-20,
.mx-20 {
  margin-right: 20px !important; }

.mb-20,
.my-20 {
  margin-bottom: 20px !important; }

.ml-20,
.mx-20 {
  margin-left: 20px !important; }

.m-25 {
  margin: 25px !important; }

.mt-25,
.my-25 {
  margin-top: 25px !important; }

.mr-25,
.mx-25 {
  margin-right: 25px !important; }

.mb-25,
.my-25 {
  margin-bottom: 25px !important; }

.ml-25,
.mx-25 {
  margin-left: 25px !important; }

.m-30 {
  margin: 30px !important; }

.mt-30,
.my-30 {
  margin-top: 30px !important; }

.mr-30,
.mx-30 {
  margin-right: 30px !important; }

.mb-30,
.my-30 {
  margin-bottom: 30px !important; }

.ml-30,
.mx-30 {
  margin-left: 30px !important; }

.m-35 {
  margin: 35px !important; }

.mt-35,
.my-35 {
  margin-top: 35px !important; }

.mr-35,
.mx-35 {
  margin-right: 35px !important; }

.mb-35,
.my-35 {
  margin-bottom: 35px !important; }

.ml-35,
.mx-35 {
  margin-left: 35px !important; }

.m-40 {
  margin: 40px !important; }

.mt-40,
.my-40 {
  margin-top: 40px !important; }

.mr-40,
.mx-40 {
  margin-right: 40px !important; }

.mb-40,
.my-40 {
  margin-bottom: 40px !important; }

.ml-40,
.mx-40 {
  margin-left: 40px !important; }

.m-45 {
  margin: 45px !important; }

.mt-45,
.my-45 {
  margin-top: 45px !important; }

.mr-45,
.mx-45 {
  margin-right: 45px !important; }

.mb-45,
.my-45 {
  margin-bottom: 45px !important; }

.ml-45,
.mx-45 {
  margin-left: 45px !important; }

.m-50 {
  margin: 50px !important; }

.mt-50,
.my-50 {
  margin-top: 50px !important; }

.mr-50,
.mx-50 {
  margin-right: 50px !important; }

.mb-50,
.my-50 {
  margin-bottom: 50px !important; }

.ml-50,
.mx-50 {
  margin-left: 50px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 1.25px !important; }

.pt-1,
.py-1 {
  padding-top: 1.25px !important; }

.pr-1,
.px-1 {
  padding-right: 1.25px !important; }

.pb-1,
.py-1 {
  padding-bottom: 1.25px !important; }

.pl-1,
.px-1 {
  padding-left: 1.25px !important; }

.p-2 {
  padding: 2.5px !important; }

.pt-2,
.py-2 {
  padding-top: 2.5px !important; }

.pr-2,
.px-2 {
  padding-right: 2.5px !important; }

.pb-2,
.py-2 {
  padding-bottom: 2.5px !important; }

.pl-2,
.px-2 {
  padding-left: 2.5px !important; }

.p-3 {
  padding: 5px !important; }

.pt-3,
.py-3 {
  padding-top: 5px !important; }

.pr-3,
.px-3 {
  padding-right: 5px !important; }

.pb-3,
.py-3 {
  padding-bottom: 5px !important; }

.pl-3,
.px-3 {
  padding-left: 5px !important; }

.p-4 {
  padding: 7.5px !important; }

.pt-4,
.py-4 {
  padding-top: 7.5px !important; }

.pr-4,
.px-4 {
  padding-right: 7.5px !important; }

.pb-4,
.py-4 {
  padding-bottom: 7.5px !important; }

.pl-4,
.px-4 {
  padding-left: 7.5px !important; }

.p-5 {
  padding: 5px !important; }

.pt-5,
.py-5 {
  padding-top: 5px !important; }

.pr-5,
.px-5 {
  padding-right: 5px !important; }

.pb-5,
.py-5 {
  padding-bottom: 5px !important; }

.pl-5,
.px-5 {
  padding-left: 5px !important; }

.p-10 {
  padding: 10px !important; }

.pt-10,
.py-10 {
  padding-top: 10px !important; }

.pr-10,
.px-10 {
  padding-right: 10px !important; }

.pb-10,
.py-10 {
  padding-bottom: 10px !important; }

.pl-10,
.px-10 {
  padding-left: 10px !important; }

.p-15 {
  padding: 15px !important; }

.pt-15,
.py-15 {
  padding-top: 15px !important; }

.pr-15,
.px-15 {
  padding-right: 15px !important; }

.pb-15,
.py-15 {
  padding-bottom: 15px !important; }

.pl-15,
.px-15 {
  padding-left: 15px !important; }

.p-20 {
  padding: 20px !important; }

.pt-20,
.py-20 {
  padding-top: 20px !important; }

.pr-20,
.px-20 {
  padding-right: 20px !important; }

.pb-20,
.py-20 {
  padding-bottom: 20px !important; }

.pl-20,
.px-20 {
  padding-left: 20px !important; }

.p-25 {
  padding: 25px !important; }

.pt-25,
.py-25 {
  padding-top: 25px !important; }

.pr-25,
.px-25 {
  padding-right: 25px !important; }

.pb-25,
.py-25 {
  padding-bottom: 25px !important; }

.pl-25,
.px-25 {
  padding-left: 25px !important; }

.p-30 {
  padding: 30px !important; }

.pt-30,
.py-30 {
  padding-top: 30px !important; }

.pr-30,
.px-30 {
  padding-right: 30px !important; }

.pb-30,
.py-30 {
  padding-bottom: 30px !important; }

.pl-30,
.px-30 {
  padding-left: 30px !important; }

.p-35 {
  padding: 35px !important; }

.pt-35,
.py-35 {
  padding-top: 35px !important; }

.pr-35,
.px-35 {
  padding-right: 35px !important; }

.pb-35,
.py-35 {
  padding-bottom: 35px !important; }

.pl-35,
.px-35 {
  padding-left: 35px !important; }

.p-40 {
  padding: 40px !important; }

.pt-40,
.py-40 {
  padding-top: 40px !important; }

.pr-40,
.px-40 {
  padding-right: 40px !important; }

.pb-40,
.py-40 {
  padding-bottom: 40px !important; }

.pl-40,
.px-40 {
  padding-left: 40px !important; }

.p-45 {
  padding: 45px !important; }

.pt-45,
.py-45 {
  padding-top: 45px !important; }

.pr-45,
.px-45 {
  padding-right: 45px !important; }

.pb-45,
.py-45 {
  padding-bottom: 45px !important; }

.pl-45,
.px-45 {
  padding-left: 45px !important; }

.p-50 {
  padding: 50px !important; }

.pt-50,
.py-50 {
  padding-top: 50px !important; }

.pr-50,
.px-50 {
  padding-right: 50px !important; }

.pb-50,
.py-50 {
  padding-bottom: 50px !important; }

.pl-50,
.px-50 {
  padding-left: 50px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 1.25px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1.25px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1.25px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1.25px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1.25px !important; }
  .m-sm-2 {
    margin: 2.5px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 2.5px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 2.5px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 2.5px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 2.5px !important; }
  .m-sm-3 {
    margin: 5px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 5px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 5px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 5px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 5px !important; }
  .m-sm-4 {
    margin: 7.5px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 7.5px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 7.5px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 7.5px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 7.5px !important; }
  .m-sm-5 {
    margin: 5px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5px !important; }
  .m-sm-10 {
    margin: 10px !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10px !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10px !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10px !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10px !important; }
  .m-sm-15 {
    margin: 15px !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15px !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15px !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15px !important; }
  .m-sm-20 {
    margin: 20px !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 20px !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 20px !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 20px !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 20px !important; }
  .m-sm-25 {
    margin: 25px !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 25px !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 25px !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 25px !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 25px !important; }
  .m-sm-30 {
    margin: 30px !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 30px !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 30px !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 30px !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 30px !important; }
  .m-sm-35 {
    margin: 35px !important; }
  .mt-sm-35,
  .my-sm-35 {
    margin-top: 35px !important; }
  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 35px !important; }
  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 35px !important; }
  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 35px !important; }
  .m-sm-40 {
    margin: 40px !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 40px !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 40px !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 40px !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 40px !important; }
  .m-sm-45 {
    margin: 45px !important; }
  .mt-sm-45,
  .my-sm-45 {
    margin-top: 45px !important; }
  .mr-sm-45,
  .mx-sm-45 {
    margin-right: 45px !important; }
  .mb-sm-45,
  .my-sm-45 {
    margin-bottom: 45px !important; }
  .ml-sm-45,
  .mx-sm-45 {
    margin-left: 45px !important; }
  .m-sm-50 {
    margin: 50px !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 50px !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 50px !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 50px !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 50px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 1.25px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1.25px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1.25px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1.25px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1.25px !important; }
  .p-sm-2 {
    padding: 2.5px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 2.5px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 2.5px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 2.5px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 2.5px !important; }
  .p-sm-3 {
    padding: 5px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 5px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 5px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 5px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 5px !important; }
  .p-sm-4 {
    padding: 7.5px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 7.5px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 7.5px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 7.5px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 7.5px !important; }
  .p-sm-5 {
    padding: 5px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5px !important; }
  .p-sm-10 {
    padding: 10px !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10px !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10px !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10px !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10px !important; }
  .p-sm-15 {
    padding: 15px !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15px !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15px !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15px !important; }
  .p-sm-20 {
    padding: 20px !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 20px !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 20px !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 20px !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 20px !important; }
  .p-sm-25 {
    padding: 25px !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 25px !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 25px !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 25px !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 25px !important; }
  .p-sm-30 {
    padding: 30px !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 30px !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 30px !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 30px !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 30px !important; }
  .p-sm-35 {
    padding: 35px !important; }
  .pt-sm-35,
  .py-sm-35 {
    padding-top: 35px !important; }
  .pr-sm-35,
  .px-sm-35 {
    padding-right: 35px !important; }
  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 35px !important; }
  .pl-sm-35,
  .px-sm-35 {
    padding-left: 35px !important; }
  .p-sm-40 {
    padding: 40px !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 40px !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 40px !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 40px !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 40px !important; }
  .p-sm-45 {
    padding: 45px !important; }
  .pt-sm-45,
  .py-sm-45 {
    padding-top: 45px !important; }
  .pr-sm-45,
  .px-sm-45 {
    padding-right: 45px !important; }
  .pb-sm-45,
  .py-sm-45 {
    padding-bottom: 45px !important; }
  .pl-sm-45,
  .px-sm-45 {
    padding-left: 45px !important; }
  .p-sm-50 {
    padding: 50px !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 50px !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 50px !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 50px !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 50px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 1.25px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1.25px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1.25px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1.25px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1.25px !important; }
  .m-md-2 {
    margin: 2.5px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 2.5px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 2.5px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 2.5px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 2.5px !important; }
  .m-md-3 {
    margin: 5px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 5px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 5px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 5px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 5px !important; }
  .m-md-4 {
    margin: 7.5px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 7.5px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 7.5px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 7.5px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 7.5px !important; }
  .m-md-5 {
    margin: 5px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5px !important; }
  .m-md-10 {
    margin: 10px !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10px !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10px !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10px !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10px !important; }
  .m-md-15 {
    margin: 15px !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15px !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 15px !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 15px !important; }
  .m-md-20 {
    margin: 20px !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 20px !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 20px !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 20px !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 20px !important; }
  .m-md-25 {
    margin: 25px !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 25px !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 25px !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 25px !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 25px !important; }
  .m-md-30 {
    margin: 30px !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 30px !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 30px !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 30px !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 30px !important; }
  .m-md-35 {
    margin: 35px !important; }
  .mt-md-35,
  .my-md-35 {
    margin-top: 35px !important; }
  .mr-md-35,
  .mx-md-35 {
    margin-right: 35px !important; }
  .mb-md-35,
  .my-md-35 {
    margin-bottom: 35px !important; }
  .ml-md-35,
  .mx-md-35 {
    margin-left: 35px !important; }
  .m-md-40 {
    margin: 40px !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 40px !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 40px !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 40px !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 40px !important; }
  .m-md-45 {
    margin: 45px !important; }
  .mt-md-45,
  .my-md-45 {
    margin-top: 45px !important; }
  .mr-md-45,
  .mx-md-45 {
    margin-right: 45px !important; }
  .mb-md-45,
  .my-md-45 {
    margin-bottom: 45px !important; }
  .ml-md-45,
  .mx-md-45 {
    margin-left: 45px !important; }
  .m-md-50 {
    margin: 50px !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 50px !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 50px !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 50px !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 50px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 1.25px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1.25px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1.25px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1.25px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1.25px !important; }
  .p-md-2 {
    padding: 2.5px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 2.5px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 2.5px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 2.5px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 2.5px !important; }
  .p-md-3 {
    padding: 5px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 5px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 5px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 5px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 5px !important; }
  .p-md-4 {
    padding: 7.5px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 7.5px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 7.5px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 7.5px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 7.5px !important; }
  .p-md-5 {
    padding: 5px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5px !important; }
  .p-md-10 {
    padding: 10px !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10px !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10px !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10px !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10px !important; }
  .p-md-15 {
    padding: 15px !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15px !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 15px !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 15px !important; }
  .p-md-20 {
    padding: 20px !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 20px !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 20px !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 20px !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 20px !important; }
  .p-md-25 {
    padding: 25px !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 25px !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 25px !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 25px !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 25px !important; }
  .p-md-30 {
    padding: 30px !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 30px !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 30px !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 30px !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 30px !important; }
  .p-md-35 {
    padding: 35px !important; }
  .pt-md-35,
  .py-md-35 {
    padding-top: 35px !important; }
  .pr-md-35,
  .px-md-35 {
    padding-right: 35px !important; }
  .pb-md-35,
  .py-md-35 {
    padding-bottom: 35px !important; }
  .pl-md-35,
  .px-md-35 {
    padding-left: 35px !important; }
  .p-md-40 {
    padding: 40px !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 40px !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 40px !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 40px !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 40px !important; }
  .p-md-45 {
    padding: 45px !important; }
  .pt-md-45,
  .py-md-45 {
    padding-top: 45px !important; }
  .pr-md-45,
  .px-md-45 {
    padding-right: 45px !important; }
  .pb-md-45,
  .py-md-45 {
    padding-bottom: 45px !important; }
  .pl-md-45,
  .px-md-45 {
    padding-left: 45px !important; }
  .p-md-50 {
    padding: 50px !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 50px !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 50px !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 50px !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 50px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 1.25px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1.25px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1.25px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1.25px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1.25px !important; }
  .m-lg-2 {
    margin: 2.5px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 2.5px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 2.5px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 2.5px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 2.5px !important; }
  .m-lg-3 {
    margin: 5px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 5px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 5px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 5px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 5px !important; }
  .m-lg-4 {
    margin: 7.5px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 7.5px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 7.5px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 7.5px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 7.5px !important; }
  .m-lg-5 {
    margin: 5px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5px !important; }
  .m-lg-10 {
    margin: 10px !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10px !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10px !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10px !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10px !important; }
  .m-lg-15 {
    margin: 15px !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15px !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15px !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15px !important; }
  .m-lg-20 {
    margin: 20px !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 20px !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 20px !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 20px !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 20px !important; }
  .m-lg-25 {
    margin: 25px !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 25px !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 25px !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 25px !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 25px !important; }
  .m-lg-30 {
    margin: 30px !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 30px !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 30px !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 30px !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 30px !important; }
  .m-lg-35 {
    margin: 35px !important; }
  .mt-lg-35,
  .my-lg-35 {
    margin-top: 35px !important; }
  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 35px !important; }
  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 35px !important; }
  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 35px !important; }
  .m-lg-40 {
    margin: 40px !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 40px !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 40px !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 40px !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 40px !important; }
  .m-lg-45 {
    margin: 45px !important; }
  .mt-lg-45,
  .my-lg-45 {
    margin-top: 45px !important; }
  .mr-lg-45,
  .mx-lg-45 {
    margin-right: 45px !important; }
  .mb-lg-45,
  .my-lg-45 {
    margin-bottom: 45px !important; }
  .ml-lg-45,
  .mx-lg-45 {
    margin-left: 45px !important; }
  .m-lg-50 {
    margin: 50px !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 50px !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 50px !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 50px !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 50px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 1.25px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1.25px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1.25px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1.25px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1.25px !important; }
  .p-lg-2 {
    padding: 2.5px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 2.5px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 2.5px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 2.5px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 2.5px !important; }
  .p-lg-3 {
    padding: 5px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 5px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 5px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 5px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 5px !important; }
  .p-lg-4 {
    padding: 7.5px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 7.5px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 7.5px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 7.5px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 7.5px !important; }
  .p-lg-5 {
    padding: 5px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5px !important; }
  .p-lg-10 {
    padding: 10px !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10px !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10px !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10px !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10px !important; }
  .p-lg-15 {
    padding: 15px !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15px !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15px !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15px !important; }
  .p-lg-20 {
    padding: 20px !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 20px !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 20px !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 20px !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 20px !important; }
  .p-lg-25 {
    padding: 25px !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 25px !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 25px !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 25px !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 25px !important; }
  .p-lg-30 {
    padding: 30px !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 30px !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 30px !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 30px !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 30px !important; }
  .p-lg-35 {
    padding: 35px !important; }
  .pt-lg-35,
  .py-lg-35 {
    padding-top: 35px !important; }
  .pr-lg-35,
  .px-lg-35 {
    padding-right: 35px !important; }
  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 35px !important; }
  .pl-lg-35,
  .px-lg-35 {
    padding-left: 35px !important; }
  .p-lg-40 {
    padding: 40px !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 40px !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 40px !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 40px !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 40px !important; }
  .p-lg-45 {
    padding: 45px !important; }
  .pt-lg-45,
  .py-lg-45 {
    padding-top: 45px !important; }
  .pr-lg-45,
  .px-lg-45 {
    padding-right: 45px !important; }
  .pb-lg-45,
  .py-lg-45 {
    padding-bottom: 45px !important; }
  .pl-lg-45,
  .px-lg-45 {
    padding-left: 45px !important; }
  .p-lg-50 {
    padding: 50px !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 50px !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 50px !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 50px !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 50px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 1.25px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1.25px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1.25px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1.25px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1.25px !important; }
  .m-xl-2 {
    margin: 2.5px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 2.5px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 2.5px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 2.5px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 2.5px !important; }
  .m-xl-3 {
    margin: 5px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 5px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 5px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 5px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 5px !important; }
  .m-xl-4 {
    margin: 7.5px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 7.5px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 7.5px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 7.5px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 7.5px !important; }
  .m-xl-5 {
    margin: 5px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5px !important; }
  .m-xl-10 {
    margin: 10px !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10px !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10px !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10px !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10px !important; }
  .m-xl-15 {
    margin: 15px !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15px !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15px !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15px !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15px !important; }
  .m-xl-20 {
    margin: 20px !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 20px !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 20px !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 20px !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 20px !important; }
  .m-xl-25 {
    margin: 25px !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 25px !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 25px !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 25px !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 25px !important; }
  .m-xl-30 {
    margin: 30px !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 30px !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 30px !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 30px !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 30px !important; }
  .m-xl-35 {
    margin: 35px !important; }
  .mt-xl-35,
  .my-xl-35 {
    margin-top: 35px !important; }
  .mr-xl-35,
  .mx-xl-35 {
    margin-right: 35px !important; }
  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 35px !important; }
  .ml-xl-35,
  .mx-xl-35 {
    margin-left: 35px !important; }
  .m-xl-40 {
    margin: 40px !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 40px !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 40px !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 40px !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 40px !important; }
  .m-xl-45 {
    margin: 45px !important; }
  .mt-xl-45,
  .my-xl-45 {
    margin-top: 45px !important; }
  .mr-xl-45,
  .mx-xl-45 {
    margin-right: 45px !important; }
  .mb-xl-45,
  .my-xl-45 {
    margin-bottom: 45px !important; }
  .ml-xl-45,
  .mx-xl-45 {
    margin-left: 45px !important; }
  .m-xl-50 {
    margin: 50px !important; }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 50px !important; }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 50px !important; }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 50px !important; }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 50px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 1.25px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1.25px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1.25px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1.25px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1.25px !important; }
  .p-xl-2 {
    padding: 2.5px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 2.5px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 2.5px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 2.5px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 2.5px !important; }
  .p-xl-3 {
    padding: 5px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 5px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 5px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 5px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 5px !important; }
  .p-xl-4 {
    padding: 7.5px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 7.5px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 7.5px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 7.5px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 7.5px !important; }
  .p-xl-5 {
    padding: 5px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5px !important; }
  .p-xl-10 {
    padding: 10px !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10px !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10px !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10px !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10px !important; }
  .p-xl-15 {
    padding: 15px !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15px !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15px !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15px !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15px !important; }
  .p-xl-20 {
    padding: 20px !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 20px !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 20px !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 20px !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 20px !important; }
  .p-xl-25 {
    padding: 25px !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 25px !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 25px !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 25px !important; }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 25px !important; }
  .p-xl-30 {
    padding: 30px !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 30px !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 30px !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 30px !important; }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 30px !important; }
  .p-xl-35 {
    padding: 35px !important; }
  .pt-xl-35,
  .py-xl-35 {
    padding-top: 35px !important; }
  .pr-xl-35,
  .px-xl-35 {
    padding-right: 35px !important; }
  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 35px !important; }
  .pl-xl-35,
  .px-xl-35 {
    padding-left: 35px !important; }
  .p-xl-40 {
    padding: 40px !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 40px !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 40px !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 40px !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 40px !important; }
  .p-xl-45 {
    padding: 45px !important; }
  .pt-xl-45,
  .py-xl-45 {
    padding-top: 45px !important; }
  .pr-xl-45,
  .px-xl-45 {
    padding-right: 45px !important; }
  .pb-xl-45,
  .py-xl-45 {
    padding-bottom: 45px !important; }
  .pl-xl-45,
  .px-xl-45 {
    padding-left: 45px !important; }
  .p-xl-50 {
    padding: 50px !important; }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 50px !important; }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 50px !important; }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 50px !important; }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 50px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

a:hover {
  cursor: pointer; }

img {
  max-width: none; }

.text-primary {
  color: #0266b3 !important; }

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.page-content {
  flex: 1; }

.page-footer {
  color: #333; }

.page-footer .footer-bottom,
.page-footer .footer-bottom a {
  color: #fff; }

.page-footer .footer-bottom a:hover {
  color: #0266b3; }

body,
footer {
  background-color: #fafafa; }

select {
  -webkit-appearance: menulist-button;
  -moz-appearance: menulist-button;
  appearance: menulist-button; }

.radio.radio-list-horizontal {
  display: flex;
  align-items: center; }
  .radio.radio-list-horizontal label {
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 20px; }
  .radio.radio-list-horizontal input {
    height: auto;
    margin: 0; }

.field-row .editing-form-label {
  display: inline-block;
  margin-bottom: 12px;
  margin-left: 2px;
  color: #484848; }

.btn-search > input[type=image] {
  height: auto;
  border-radius: 30px;
  padding: 11px 27px;
  background: #e5e5e5; }

a[disabled],
input[type=submit][disabled],
button[disabled] {
  pointer-events: none; }

.explanation-text {
  color: #6c757d !important; }

.field-row .explanation-text {
  display: block;
  margin-top: .25rem;
  font-size: 14px; }

.payment-desc {
  display: block;
  margin-top: 5px; }

input.disabled,
select.disabled {
  pointer-events: none;
  background-color: #e9ecef; }

.form-login, .form-register {
  height: auto;
  position: relative;
  padding: 44px 50px 50px 50px; }

.ErrorLabel,
.form-control-error,
.error-label {
  color: #b90000;
  display: block;
  padding-bottom: 10px;
  text-align: left; }

.InfoLabel {
  color: #009e0d;
  display: block;
  padding-bottom: 10px;
  text-align: left; }

.form-horizontal h4 {
  color: #0266b3;
  margin-bottom: 15px; }

.form-horizontal .field-row {
  margin-bottom: 15px; }
  .form-horizontal .field-row .editing-form-label {
    margin-bottom: 0; }

#table-cart th,
#table-cart td {
  padding-left: 10px;
  padding-right: 10px; }

#table-cart th {
  text-align: center; }

#table-cart td {
  width: auto; }
  #table-cart td:not(:first-child) {
    text-align: center; }

#table-cart td:first-child {
  max-width: 250px; }

#table-cart .price {
  text-align: center; }

#table-cart .quanlity {
  display: inline-block; }

.cart-totals .product-table-container {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5; }

.cart-totals table.product th,
.cart-totals table.product td {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.cart-totals table.product td a {
  color: #838383; }
  .cart-totals table.product td a:hover {
    color: #0266b3; }

.result-item {
  margin-top: 30px; }
  .result-item:first-child {
    margin-top: 0; }
  .result-item .result-image {
    width: 200px;
    margin-right: 20px;
    flex-shrink: 0; }
    .result-item .result-image a {
      display: block; }
  .result-item .result-type {
    color: #919191;
    font-size: 13px;
    margin-bottom: 10px; }
  .result-item .result-title {
    color: #484848;
    font-size: 16px; }
  .result-item .result-status {
    color: #f92400;
    margin: 3px 0 9px;
    font-size: 13px; }
  .result-item .result-description {
    margin-top: 20px; }
  .result-item .regular {
    font-size: 16px; }

.btn-search input[type=image] {
  background: #484848; }

#article-list .more-link a {
  width: auto;
  padding-left: 30px;
  padding-right: 30px; }

.attachments {
  margin: 35px 0 35px; }

.attachments-heading {
  margin-bottom: 15px; }

.files a:before,
.files a[href*=".pdf"]:before,
.files a[href*=".doc"]:before,
.files a[href*=".docx"]:before,
.files a[href*=".xls"]:before,
.files a[href*=".xlsx"]:before,
.files a[href*=".jpg"]:before,
.files a[href*=".jpeg"]:before,
.files a[href*=".png"]:before,
.files a[href*=".avi"]:before,
.files a[href*=".mp4"]:before,
.files a[href*=".txt"]:before,
.files a[href*=".ppt"]:before,
.files a[href*=".pptx"]:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 21px;
  margin-right: 10px;
  content: '\f016'; }

.files a[href*=".pdf"]:before {
  color: #cb0606;
  content: '\f1c1'; }

.files a[href*=".doc"]:before,
.files a[href*=".docx"]:before {
  color: #2b579a;
  content: "\f1c2"; }

.files a[href*=".xls"]:before,
.files a[href*=".xlsx"]:before {
  color: #1f7246;
  content: "\f1c3"; }

.files a[href*=".jpg"]:before,
.files a[href*=".jpeg"]:before,
.files a[href*=".png"]:before {
  color: #175b98;
  content: "\f1c5"; }

.files a[href*=".avi"]:before,
.files a[href*=".mp4"]:before {
  content: "\f1c8"; }

.files a[href*=".txt"]:before {
  color: #157efb;
  content: "\f0f6"; }

.files a[href*=".ppt"]:before,
.files a[href*=".pptx"]:before {
  color: #d14424;
  content: "\f1c4"; }

.files a[href*=".zip"]:before,
.files a[href*=".rar"]:before {
  color: #ff9100;
  content: "\f1c6"; }

.files-list {
  padding: 20px;
  background-color: #f7f7f7; }
  .files-list:first-child {
    margin-top: 0; }
  .files-list:last-child {
    margin-bottom: 0; }
  .files-list .list-heading {
    margin-top: 0;
    margin-bottom: 18px; }
  .files-list ul {
    list-style: none; }
  .files-list li {
    margin-top: 8px; }
    .files-list li:first-child {
      margin-top: 0; }
  .files-list a:hover {
    text-decoration: underline; }

#product-list .imagebox.style1.v3,
.product-box .imagebox.style1.v3 {
  display: flex; }

#product-list .box-image a,
.product-box .box-image a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }

#product-list .box-image img,
.product-box .box-image img {
  align-self: center;
  border: 1px solid #e0e0e0; }

#product-list .box-content,
.product-box .box-content {
  width: auto;
  flex: 1 0 0; }

#product-list .box-price,
.product-box .box-price {
  max-width: 200px;
  text-align: right;
  margin-left: auto;
  padding-left: 40px; }

#product-list .product-name a,
.product-box .product-name a {
  font-size: 16px; }

#product-list .cat-name,
.product-box .cat-name {
  font-size: 13px; }

#product-list .box-price,
.product-box .box-price {
  display: flex;
  flex-direction: column; }
  #product-list .box-price .regular,
  .product-box .box-price .regular {
    font-size: 16px;
    color: #f00; }
    #product-list .box-price .regular > span,
    .product-box .box-price .regular > span {
      color: #777; }

#product-list .btn-add-cart,
.product-box .btn-add-cart {
  display: block;
  max-width: 100%; }

#product-list .box-cart,
.product-box .box-cart {
  margin: 0; }

#product-list .add-to-cart-container,
.product-box .add-to-cart-container {
  display: flex;
  align-items: center; }

#product-list .add-to-cart-link,
.product-box .add-to-cart-link {
  padding: 0;
  margin: 0;
  width: auto;
  background-image: url(/StaticFiles/template/images/icons/add-cart.png);
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -9999px;
  max-width: 100%; }

#product-list .quanlity-box,
#product-list .box-cart,
.product-box .quanlity-box,
.product-box .box-cart {
  width: 50%;
  padding: 0 5px; }

#product-list .quanlity input,
.product-box .quanlity input {
  padding: 10px; }

#product-list .box-image {
  background-color: #fff; }

#product-detail .zoom[href="#"] {
  pointer-events: none; }

#product-detail .add-to-cart-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

#product-detail .add-to-cart-link {
  background-image: url(/StaticFiles/template/images/icons/add-cart.png);
  background-position: 25px center;
  background-repeat: no-repeat;
  padding-left: 70px;
  padding-right: 40px;
  width: auto; }

#product-detail .product-detail {
  padding: 10px 15px; }

#product-detail .status-product span {
  padding: 0;
  height: auto;
  margin: 0;
  line-height: 1;
  background-color: transparent; }

#product-detail .header-detail .heading {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 5px;
  padding-bottom: 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  width: 100%; }
  #product-detail .header-detail .heading span {
    margin-right: 13px;
    font-style: italic;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap; }

#product-detail .header-detail h4.name {
  font-size: 23px;
  margin: 0; }

#product-detail .header-detail .manufacturer {
  margin-left: auto;
  width: 110px;
  flex-shrink: 0; }
  #product-detail .header-detail .manufacturer img {
    object-fit: contain; }

#product-detail .header-detail .category a {
  color: #0266b3;
  display: block;
  font-size: 14px; }

#product-detail .box-cart.style2 {
  margin-bottom: 0; }

#product-detail .call-for-price > span {
  display: inline-block;
  padding: 10px 25px;
  margin-top: 30px;
  background-color: #cee6ce;
  border-radius: 30px; }

.flat-product-detail {
  padding-bottom: 0; }

.imagebox.style4 .box-content .product-name {
  line-height: 1.2; }
  .imagebox.style4 .box-content .product-name a {
    line-height: 1; }

.flat-product-content {
  background-color: transparent; }
  .flat-product-content .ajax__tab_header {
    text-align: center; }
  .flat-product-content .ajax__tab_tab {
    display: inline-block;
    color: #919191;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Open Sans';
    cursor: pointer;
    padding: 14px 30px 14px 26px;
    position: relative;
    z-index: 5; }
    .flat-product-content .ajax__tab_tab:before {
      content: '';
      position: absolute;
      background-color: #f5f5f5;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      top: 0px;
      left: 0;
      width: 100%;
      height: 52px;
      z-index: -1;
      opacity: 0; }
  .flat-product-content .ajax__tab_active .ajax__tab_tab {
    color: #2d2d2d; }
    .flat-product-content .ajax__tab_active .ajax__tab_tab:before {
      opacity: 1; }
  .flat-product-content .ajax__tab_body {
    background: #f5f5f5;
    padding: 45px 0 25px; }

.tecnical-specs {
  text-align: center; }
  .tecnical-specs table tr td {
    padding-right: 45px;
    text-align: left;
    padding-left: 45px;
    width: 50%; }
  .tecnical-specs table tr td:first-child {
    text-align: right; }

.text-in-stock, #product-detail .status-product span.text-in-stock {
  color: #31984b; }

.text-out-of-stock, #product-detail .status-product span.text-out-of-stock {
  color: #da2626; }

.flat-brand .logo-container img {
  object-fit: contain; }

#mini-cart .inner-box .dropdown-box > ul > li .img-product {
  border: none !important; }

#mini-cart.box-cart .inner-box .dropdown-box {
  box-shadow: 2px 2px 11px 1px rgba(72, 72, 72, 0.25); }

.cart-totals {
  position: relative; }
  .cart-totals h4 {
    margin-bottom: 10px; }
  .cart-totals td {
    line-height: 1.25; }
  .cart-totals .btn-cart-totals a {
    padding: 0;
    border: 0; }

.btn-order {
  padding: 0;
  text-align: center; }
  .btn-order a {
    display: inline-block;
    padding: 0 50px;
    line-height: 58px; }

.fancybox-popup {
  max-width: 450px;
  padding: 40px; }

.widget-categories > ul.cat-list > li > a {
  display: flex; }
  .widget-categories > ul.cat-list > li > a:before {
    top: 0; }
  .widget-categories > ul.cat-list > li > a.active {
    font-weight: bold;
    color: #0266b3; }
  .widget-categories > ul.cat-list > li > a:hover {
    color: #0266b3; }

.box-checkbox .check-box label:before {
  transition: none !important; }

.box-checkbox .check-box a {
  color: inherit; }

.box-checkbox .check-box.active label:after, .box-checkbox .check-box:hover label:after {
  color: #fff;
  font-family: 'FontAwesome';
  content: '\f00c'; }

.box-checkbox .check-box:hover label:before {
  background-color: #797979;
  border-color: #797979; }

.box-checkbox .check-box.active label:before {
  background-color: #0266b3;
  border-color: #0266b3; }

.box-checkbox .check-box.active a {
  color: #0266b3; }

.box-contact p {
  margin-bottom: 8px;
  font-size: 14px; }
  .box-contact p:last-child {
    margin-bottom: 0; }
  .box-contact p em {
    color: #0266b3; }

.content-detail .price .regular {
  font-size: 16px;
  color: #f00; }
  .content-detail .price .regular > span {
    color: #777; }

.flat-account .accordion .accordion-toggle .toggle-title:before, .flat-account .accordion .accordion-toggle .toggle-title:after {
  top: 50%;
  margin-top: -8px; }

.flat-account .accordion table {
  margin-bottom: 7px; }
  .flat-account .accordion table:last-child {
    margin-top: 0; }

#breadcrumbs {
  padding-bottom: 6px; }
  #breadcrumbs li {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px; }
  #breadcrumbs span {
    float: none; }

.page-home .logo-container {
  height: 60px; }

[style*='--aspect-ratio'] {
  position: relative; }
  [style*='--aspect-ratio']::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio))); }
  [style*='--aspect-ratio'] img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center; }

textarea {
  max-width: 100%;
  resize: vertical;
  min-height: 100px;
  max-height: 250px; }

.spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10000; }
  .spinner i {
    font-size: 30px; }
  .spinner.page-spinner {
    position: fixed; }
    .spinner.page-spinner i {
      font-size: 70px;
      color: #0266b3; }

.fit-contain,
.fit-contain img {
  object-fit: contain; }

html,
body {
  overflow-y: unset; }

body {
  font-size: 15px;
  color: #212121; }

.flat-checkout {
  margin-bottom: 30px; }

.table-cart tr td .quanlity input {
  width: 90px; }

.table-cart tr td .quanlity > div {
  display: flex;
  align-items: center; }

.table-cart .UnitCountButton {
  margin-left: 10px; }

.table-cart tr td .price {
  white-space: nowrap; }

.fields-title {
  margin-bottom: 20px; }
  .fields-title h3 {
    color: #0266b3; }

.fields-content .field-row {
  margin-bottom: 17px; }

.fields-content {
  padding: 25px;
  margin-bottom: 25px;
  border: 1px solid #e5e5e5; }

.required-mark {
  color: #dc3545; }

.form-coupon {
  padding: 0;
  border: 0;
  margin: 5px 0;
  text-align: left; }

.header-middle #logo {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }
  .header-middle #logo a {
    display: block;
    text-align: center; }
  .header-middle #logo img {
    height: auto;
    max-height: 100px;
    max-width: 100%; }

#mainnav.style1 {
  padding-left: 25px; }

.brands-list .brands-item > a {
  height: 188px; }
  .brands-list .brands-item > a img {
    object-fit: contain; }

.top-search.style1 .form-search .box-search .btn-search {
  bottom: 0; }

.top-search.style1 .form-search .box-search input[type=text] {
  padding-left: 30px;
  padding-top: 7px; }

.top-search.style1 .form-search .box-search input[type=image] {
  display: block; }

.today-deal a {
  font-size: 16px;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 25px; }
  .today-deal a:before {
    left: 0;
    bottom: 4px;
    margin-left: auto;
    margin-right: auto; }
  .today-deal a:hover:before {
    width: 80%; }
  .today-deal a.active {
    background: #484848; }
    .today-deal a.active:before {
      content: none !important; }

.brands-tablist li {
  margin-right: 18px; }

#mega-menu > ul.menu {
  height: auto;
  transition-duration: 0.1s; }

#mega-menu:hover ul.menu {
  transition-delay: 350ms; }

.btn-mega {
  display: block; }
  .btn-mega.inactive {
    background-color: #777777 !important; }

#mega-menu > ul.menu > li .drop-menu {
  background-color: transparent;
  padding: 0;
  transition-duration: 150ms;
  max-height: 840px;
  height: auto;
  display: flex; }

#mega-menu > ul.menu > li a.active:after,
#mega-menu > ul.menu > li a.active .menu-title {
  color: #0266b3; }

#mega-menu > ul.menu > li .drop-menu-inner {
  margin-left: 10px;
  max-height: 100%;
  width: 100%;
  background-color: #fff;
  padding: 27px 7px 27px 0;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap; }
  #mega-menu > ul.menu > li .drop-menu-inner a {
    display: block;
    line-height: 1.2; }
  #mega-menu > ul.menu > li .drop-menu-inner ul li {
    margin-bottom: 10px !important; }
  #mega-menu > ul.menu > li .drop-menu-inner .show a {
    display: inline-block; }

#mega-menu > ul.menu > li > a {
  transition: unset; }

.flexslider .slides {
  display: flex;
  align-items: center; }
  .flexslider .slides img {
    margin-left: auto;
    margin-right: auto; }

.flex-control-thumbs {
  display: flex;
  flex-wrap: wrap; }
  .flex-control-thumbs li {
    margin-right: 13px;
    margin-bottom: 10px;
    width: 100px; }

.slider .slider-item.style5 {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .slider .slider-item.style5:before {
    display: none !important; }

.owl-carousel-3 {
  visibility: hidden; }
  .owl-carousel-3.owl-loaded {
    visibility: visible; }
  .owl-carousel-3.hide-nav .owl-controls {
    display: none; }

.owl-carousel-8.owl-theme .owl-controls {
  background-color: transparent; }

.main-blog {
  margin-bottom: 40px; }

.main-post .post-image {
  margin-bottom: 20px; }

.main-post .post-header {
  margin-bottom: 25px; }

.main-post .title-post {
  margin-bottom: 5px; }

.main-post .meta-post {
  margin-bottom: 0; }

.article-list .main-post {
  display: flex;
  max-width: 1040px;
  margin-bottom: 55px; }
  .article-list .main-post .post-image {
    flex-shrink: 0;
    width: 300px;
    margin-right: 40px; }
  .article-list .main-post .post-header {
    margin-bottom: 20px; }
  .article-list .main-post .title-post {
    margin-bottom: 5px !important; }
  .article-list .main-post .meta-post {
    margin-bottom: 0 !important; }

.btn-order a {
  color: #fff !important; }

#mainnav ul.menu > li:hover > .submenu {
  transition-delay: 200ms !important; }

#mainnav.style1 ul.menu > li > a {
  margin-right: 24px; }
  #mainnav.style1 ul.menu > li > a:hover {
    color: #0266b3; }

.btn-scroll {
  position: absolute;
  right: 0;
  bottom: 0; }

.wrap-imagebox .box-product .row .imagebox {
  min-height: 100%; }

.imagebox .box-content .cat-name a:before,
.imagebox .box-content .cat-name a:after {
  content: none; }

.imagebox .box-image a {
  display: inline; }
  .imagebox .box-image a img {
    max-height: 100%;
    align-self: center; }

.counter.style1 {
  padding-left: 40px;
  padding-right: 40px; }

.counter.style1.v1 {
  width: calc(100% - 1px); }

.flat-breadcrumb ul.breadcrumbs li.trail-end a {
  pointer-events: none; }

.widget-banner img {
  max-width: 100%; }

.accordion-toggle .toggle-title {
  padding: 15px 33px; }

.team-member .member .image-member img {
  max-width: 100%; }

.widget-newsletter .subscribe-form .Subscription {
  position: relative; }

.widget-newsletter .subscribe-form .subscription-email {
  padding-right: 55px; }

.widget-newsletter .subscribe-form input[type=image] {
  position: absolute;
  top: 0;
  right: -3px;
  background-color: transparent;
  padding: 19px 25px; }

.widget-newsletter .subscribe-form .EditingFormInfoLabel {
  color: #238f31; }

.widget-newsletter .subscribe-form .EditingFormErrorLabel {
  color: #b42121; }

.wrap-error .error-heading {
  font-weight: bold;
  color: #0266b3;
  font-size: 12rem;
  line-height: 1; }

.wrap-error .thank-you-heading {
  font-size: 7rem; }

.sort-product.style1 {
  padding-top: 10px;
  padding-bottom: 10px; }
  .sort-product.style1 select {
    appearance: none;
    width: auto !important;
    padding-right: 45px !important; }

.counter.style1,
.flat-iconbox,
.boxed.style1 .flat-row.flat-slider.style3,
.flexslider,
.product-detail.style4 {
  background-color: transparent; }
