a:hover {
	cursor: pointer;
}

img {
	max-width: none;
}

.text-primary {
	color: $primary!important;
}