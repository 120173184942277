.page-home {
	// #mega-menu > ul.menu {
	// 	visibility: visible;
	// 	opacity: 1;
	// 	top: 0;
	// }

	.logo-container {
		height: 60px;
	}
}

