@mixin fa {
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin disabled {
	background-color: #a7a7a7!important;
	transition: none;
	position: relative;
	
	&:before {
		content: none!important;
	}

	.fa {
		position: absolute;
		right: 18px;
		top: 50%;
		font-size: 20px;
		margin-top: -10px;
	}
}

[style*='--aspect-ratio'] {
	position: relative;

	&::before {
		content: '';
		display: block;
		padding-bottom: calc(100% / (var(--aspect-ratio)));
	}

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top center;
	}
}

textarea {
	max-width: 100%;
	resize: vertical;
	min-height: 100px;
	max-height: 250px;
}

.spinner {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.6);
	z-index: 10000;

	i {
		font-size: 30px;
	}

	&.page-spinner {
		position: fixed;

		i {
			font-size: 70px;
			color: $primary;
		}
	}
}

.fit-contain,
.fit-contain img {
	object-fit: contain;
}