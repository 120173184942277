#mini-cart {
	.inner-box .dropdown-box > ul > li .img-product {
		border: none!important;
	}

	&.box-cart .inner-box .dropdown-box {
		box-shadow: 2px 2px 11px 1px rgba(72, 72, 72, 0.25);
   }	
}

.cart-totals {
	position: relative;
	
	h4 {
		margin-bottom: 10px;
	}

	td {
		line-height: 1.25;
	}

	.btn-cart-totals a {
		padding: 0;
		border: 0;
	}
}

.btn-order {
	padding: 0;
	text-align: center;

	a {
		display: inline-block;
		padding: 0 50px;
		line-height: 58px;
	}
}