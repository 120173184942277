.page-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.page-content {
	flex: 1;
}

.page-footer {
	color: #333;
}

.page-footer .footer-bottom {
	&,
	a {
		color: #fff;
	}

	a:hover {
		color: $primary;
	}
}

body,
footer {
	background-color: #fafafa;
}