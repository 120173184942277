.widget-categories > ul.cat-list > li > a {
	display: flex;

	&:before {
		top: 0;
	}

	&.active {
		font-weight: bold;
		color: $primary;
	}

	&:hover {
		color: $primary;
	}

	// &:not(.active):not(:hover):before {
	// 	content: none;
	// }
}

.box-checkbox {
	.check-box {
		label:before {
			transition: none!important;
		}

		a {
			color: inherit;
		}


		&.active,
		&:hover {
			label:after {
				color: #fff;
				font-family: 'FontAwesome';
				content: '\f00c';
			}
		}

		&:hover label:before {
			background-color: #797979;
			border-color: #797979;
		}

		&.active {
			label:before {
				background-color: $primary;
				border-color: $primary;
			}

			a {
				color: $primary;
			}
		}
	}
}
