#breadcrumbs {
	padding-bottom: 6px;
	
	li {
		display: inline-flex;
		align-items: center;
		margin-bottom: 10px;
	}

	span {
		float: none;
	}
}