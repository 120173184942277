$spacer: 5px;
$spacers: (
	0: 0,
	5: $spacer,
	10: (
		$spacer * 2
	),
	15: (
		$spacer * 3
	),
	20: (
		$spacer * 4
	),
	25: (
		$spacer * 5
	),
	30: (
		$spacer * 6
	),
	35: (
		$spacer * 7
	),
	40: (
		$spacer * 8
	),
	45: (
		$spacer * 9
	),
	50: (
		$spacer * 10
	)
) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
	(
		0: 0,
		1: (
			$spacer * 0.25
		),
		2: (
			$spacer * 0.5
		),
		3: $spacer,
		4: (
			$spacer * 1.5
		),
		5: (
			$spacer * 3
		)
	),
	$spacers
);

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		@each $prop, $abbrev in (margin: m, padding: p) {
			@each $size, $length in $spacers {
				.#{$abbrev}#{$infix}-#{$size} {
					#{$prop}: $length !important;
				}
				.#{$abbrev}t#{$infix}-#{$size},
				.#{$abbrev}y#{$infix}-#{$size} {
					#{$prop}-top: $length !important;
				}
				.#{$abbrev}r#{$infix}-#{$size},
				.#{$abbrev}x#{$infix}-#{$size} {
					#{$prop}-right: $length !important;
				}
				.#{$abbrev}b#{$infix}-#{$size},
				.#{$abbrev}y#{$infix}-#{$size} {
					#{$prop}-bottom: $length !important;
				}
				.#{$abbrev}l#{$infix}-#{$size},
				.#{$abbrev}x#{$infix}-#{$size} {
					#{$prop}-left: $length !important;
				}
			}
		}

		// Some special margin utils
		.m#{$infix}-auto {
			margin: auto !important;
		}
		.mt#{$infix}-auto,
		.my#{$infix}-auto {
			margin-top: auto !important;
		}
		.mr#{$infix}-auto,
		.mx#{$infix}-auto {
			margin-right: auto !important;
		}
		.mb#{$infix}-auto,
		.my#{$infix}-auto {
			margin-bottom: auto !important;
		}
		.ml#{$infix}-auto,
		.mx#{$infix}-auto {
			margin-left: auto !important;
		}
	}
}
