.result-item {
	margin-top: 30px;

	&:first-child {
		margin-top: 0;
	}

	.result-image {
		width: 200px;
		margin-right: 20px;
		flex-shrink: 0;

		a {
			display: block;
		}
	}

	.result-type {
		color: #919191;
		font-size: 13px;
		margin-bottom: 10px;
	}

	.result-title {
		color: #484848;
		font-size: 16px;
	}

	.result-status {
		color: #f92400;
		margin: 3px 0 9px;
		font-size: 13px;
	}

	.result-description {
		margin-top: 20px;
	}
	.regular {
		font-size: 16px;
	}	
}

.btn-search {
	input[type=image] {
		background: #484848;
	}
}