.flat-account {
	.accordion {
		.accordion-toggle .toggle-title {
			&:before,
			&:after {
				top: 50%;
				margin-top: -8px;
			}
		}

		table {
			margin-bottom: 7px;

			&:last-child { margin-top: 0; }
		}
	}
}
