select {
	-webkit-appearance:menulist-button;
	-moz-appearance:menulist-button;
	appearance:menulist-button;
}

// select:before {
// 	content:'\f107';
// 	position:absolute;
// 	font-family:'Fontawesome';
// 	top: 50%;
// 	margin-top: -7px;
// 	right:22px;
// 	font-size:14px;
// 	color:#1b1b19;
// 	z-index:2;
// }

.radio.radio-list-horizontal {
	display: flex;
	align-items: center;

	label {
		margin-bottom: 0;
		margin-left: 5px;
		margin-right: 20px;
	}

	input {
		height: auto;
		margin: 0;
	}
}

.field-row {
	.editing-form-label {
		display: inline-block;
		margin-bottom: 12px;
		margin-left: 2px;
		color: #484848;
	}
}

.btn-search > input[type=image] {
	height: auto;
    border-radius: 30px;
    padding: 11px 27px;
    background: #e5e5e5;
}


a[disabled],
input[type=submit][disabled],
button[disabled] {
	pointer-events: none;
}

.explanation-text {
	// text-muted
	color: #6c757d!important;
}

.field-row .explanation-text {
	// form-text
	display: block;
	margin-top: .25rem;
	font-size: 14px;
}

.payment-desc {
	display: block;
	margin-top: 5px;
}

input,
select {
	&.disabled {
		pointer-events: none;
		background-color: #e9ecef;
	}
}

.form-login, .form-register {
	height: auto;
	position: relative;
	padding: 44px 50px 50px 50px;
}

.ErrorLabel,
.form-control-error,
.error-label {
	color: #b90000;
    display: block;
    padding-bottom: 10px;
    text-align: left;
}

.InfoLabel {
	color: #009e0d;
    display: block;
    padding-bottom: 10px;
    text-align: left;
}

.form-horizontal {
	h4 {
		color: #0266b3;
		margin-bottom: 15px;
	}
	.field-row {
		margin-bottom: 15px;

		.editing-form-label {
			margin-bottom: 0;
		}
	}
}