html,
body {
	overflow-y: unset;
}

body {
	font-size: 15px;
	color: #212121;
}

.flat-checkout {
	margin-bottom: 30px;
}

.table-cart tr td .quanlity input {
	width: 90px;
}

.table-cart tr td .quanlity > div {
	display: flex;
	align-items: center;
}

.table-cart .UnitCountButton {
	margin-left: 10px;
}

.table-cart tr td .price {
	white-space: nowrap;
}

.fields-title {
	margin-bottom: 20px;
	h3 {
		color: $primary;
	}
}

.fields-content .field-row {
	margin-bottom: 17px;
}

.fields-content {
	padding: 25px;
	margin-bottom: 25px;
	border: 1px solid #e5e5e5;

	// &:last-child {
	// padding-bottom: 0;
	// margin-bottom: 0;
	// border-bottom: 0;
	// }
}

.required-mark {
	color: $danger;
}

.form-coupon {
	padding: 0;
	border: 0;
	margin: 5px 0;
	text-align: left;
}

.header-middle #logo {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
	
	a {
		display: block;
		text-align: center;
	}

	img {
		height: auto;
		max-height: 100px;
		max-width: 100%;
	}
}

#mainnav.style1 {
	padding-left: 25px;
}

.brands-list .brands-item > a {
	height: 188px;

	img {
		object-fit: contain;
	}
}

.top-search.style1 .form-search .box-search {
	.btn-search {
		bottom: 0;
	}
	input[type=text] {
		padding-left: 30px;
		padding-top: 7px;
	}

	input[type=image] {
		display: block;
	}	
}

.today-deal a {
	font-size: 16px;
	font-size: 16px;
	padding: 10px 20px;
	border-radius: 25px;

	&:before {
		left: 0;
		bottom: 4px;
		margin-left: auto;
		margin-right: auto;
	}

	&:hover:before {
		width: 80%;
		// margin-left: 10px;
		// margin-right: 10px;
	}

	&.active {
		background: #484848;

		&:before {
			content: none !important;
		}
	}
}

.brands-tablist {
	li {
		margin-right: 18px;
	}
}

#mega-menu > ul.menu {
	height: auto;
	transition-duration: 0.1s;
}

#mega-menu:hover ul.menu {
	transition-delay: 350ms;
}

.btn-mega {
	display: block;

	&.inactive {
		background-color: #777777 !important;
	}
}

#mega-menu > ul.menu > li {
	.drop-menu {
		background-color: transparent;
		padding: 0;
		transition-duration: 150ms;
		max-height: 840px;
		height: auto;
		display: flex;
	}

	a.active {
		&:after,
		.menu-title {
			color: $primary;
		}
	}

	.drop-menu-inner {
		margin-left: 10px;
		max-height: 100%;
		width: 100%;
		background-color: #fff;
		padding: 27px 7px 27px 0;
		border-radius: 10px;
		display: flex;
		flex-wrap: wrap;

		a {
			display: block;
			line-height: 1.2;
		}

		ul li {
			margin-bottom: 10px !important;
		}

		.show a {
			display: inline-block;
		}
	}

	> a {
		transition: unset;
	}
}

.flexslider .slides {
	display: flex;
	align-items: center;

	img {
		margin-left: auto;
		margin-right: auto;
	}
}

.flex-control-thumbs {
	display: flex;
	flex-wrap: wrap;

	li {
		margin-right: 13px;
		margin-bottom: 10px;
		width: 100px;
	}
}

.slider .slider-item.style5 {
	display: block;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	&:before {
		display: none !important;
	}
}

.owl-carousel-3 {
	visibility: hidden;

	&.owl-loaded {
		visibility: visible;
	}
	&.hide-nav .owl-controls {
		display: none;
	}
}

.owl-carousel-8.owl-theme .owl-controls {
	background-color: transparent;
}

.main-blog {
	margin-bottom: 40px;
}

.main-post {
	.post-image {
		margin-bottom: 20px;
	}

	.post-header {
		margin-bottom: 25px;
	}
	.title-post {
		margin-bottom: 5px;
	}

	.meta-post {
		margin-bottom: 0;
	}
}

.article-list .main-post {
	display: flex;
	max-width: 1040px;
	margin-bottom: 55px;

	.post-image {
		flex-shrink: 0;
		width: 300px;
		margin-right: 40px;
	}

	.post-header {
		margin-bottom: 20px;
	}

	.title-post {
		margin-bottom: 5px !important;
	}

	.meta-post {
		margin-bottom: 0 !important;
	}
}

.btn-order a {
	color: #fff !important;
}

#mainnav ul.menu > li:hover > .submenu {
	transition-delay: 200ms!important;
}
#mainnav.style1 ul.menu > li > a {
	margin-right: 24px;

	&:hover {
		color: $primary;
	}
}

.btn-scroll {
	position: absolute;
	right: 0;
	bottom: 0;
}

.wrap-imagebox .box-product .row .imagebox {
	min-height: 100%;
}

.imagebox {
	.box-content .cat-name a:before,
	.box-content .cat-name a:after {
		content: none;
	}

	.box-image a {
		display: inline;

		img {
			max-height: 100%;
			align-self: center;
		}
	}
}

.counter.style1 {
	padding-left: 40px;
	padding-right: 40px;
}

.counter.style1.v1 {
	width: calc(100% - 1px);
}

// breadcrumbs
.flat-breadcrumb ul.breadcrumbs li.trail-end a {
	pointer-events: none;
}

.widget-banner {
	img {
		max-width: 100%;
	}
}

// FAQ
.accordion-toggle .toggle-title {
	padding: 15px 33px;
}

.team-member .member .image-member img {
	max-width: 100%;
}

.widget-newsletter .subscribe-form {
	.Subscription {
		position: relative;
	}

	.subscription-email {
		padding-right: 55px;		
	}

	input[type=image] {
		position: absolute;
		top: 0;
		right: -3px;
		background-color: transparent;
		padding: 19px 25px;
	}

	.EditingFormInfoLabel {
		color: #238f31;
	}
	.EditingFormErrorLabel {
		color: #b42121;
	}
} 
	
.wrap-error {
	.error-heading {
		font-weight: bold;
		color: $primary;
		font-size: 12rem;
		line-height: 1;
	}

	.thank-you-heading {
		font-size: 7rem;
	}
}
	

.sort-product.style1 {
	padding-top: 10px;
	padding-bottom: 10px;

	select {
		appearance: none;
		width: auto!important;
		padding-right: 45px!important;
	}
}

.counter.style1,
.flat-iconbox,
.boxed.style1 .flat-row.flat-slider.style3,
.flexslider,
.product-detail.style4 {
	background-color: transparent;
}