.box-contact {
    p {
        margin-bottom: 8px;
        font-size: 14px;

        &:last-child {
            margin-bottom: 0;
        }

        em {
            color: $primary;
        }
    }
}

.content-detail {
    .price {
        .regular {
            font-size: 16px;
            color: #f00;

            > span {
                color: #777;
            }
        }
    }
}